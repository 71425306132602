import Service from '@/services/Service'

export default class extends Service {
  constructor (productId, id) {
    const url = ['inventory', 'products', productId, 'materials']
    if (id) {
      url.push(id)
    }
    super(url)
  }
}
