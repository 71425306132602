<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="show" append-to-body title="Add New Material">
    <gf-form v-loading="loading ">
      <el-form ref="productMaterial" :model="productMaterial" :rules="productMaterialRule">
        <el-form-item label="Quantity" prop="quantity">
          <el-input v-model="productMaterial.quantity" placeholder="Quantity"></el-input>
        </el-form-item>
        <el-form-item label="Material Name" prop="variant_id">
          <el-select v-model="productMaterial.variant_id" :remote-method="searchMaterials" filterable
                     placeholder="Start typing SKU or Name"
                     remote style="width: 100%">
            <el-option v-for="variant in pVariants" :key="variant.id" :label="variant.name"
                       :value="variant.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <close-button @click="show = false" />
        <add-button text="Add" @click="confirmAddMaterial" />
      </template>
    </gf-form>
  </el-dialog>
</template>

<script>
import GfForm from '@/custom-elements/gf-form'
import AddButton from '@/component-v2/AddButton'
import CloseButton from '@/component-v2/CloseButton'
import MaterialService from '@/services/v1/Material'
import ProductMaterialService from '@/services/v1/ProductMaterial'

export default {
  components: { CloseButton, GfForm, AddButton },
  props: {
    visible: Boolean,
    refresh: Function,
    isDraft: Boolean
  },
  data () {
    return {
      show: false,
      loading: false,
      productMaterial: {
        quantity: 0,
        variant_id: null
      },
      pVariants: [],

      // Rules
      productMaterialRule: {
        quantity: [
          { required: true, message: 'Quantity is require', trigger: 'blur' }
        ],
        variant_id: [
          { required: true, message: 'Material is require', trigger: 'blur' }
        ]
      },

      materialFilter: {}
    }
  },
  watch: {
    show (newVal) {
      this.$emit('update:visible', newVal)
    },
    visible () {
      this.show = this.visible
    }
  },
  methods: {
    confirmAddMaterial () {
      this.$refs.productMaterial.validate()
        .then(() => {
          if (!this.isDraft) {
            this.$confirm('You are updating the quantity of the material of the product. Do you also want to adjust the stock associated with it?', 'Notification', {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'info',
              distinguishCancelAndClose: true
            }).then(() => {
              this.addMaterial(true)
            }).catch((action) => {
              if (action === 'cancel') this.addMaterial(false)
            })
          } else {
            this.addMaterial(false)
          }
        })
        .catch(() => {
        })
    },
    async addMaterial (useStock) {
      try {
        this.loading = true
        const productMaterialService = new ProductMaterialService(this.$route.params.id)
        await productMaterialService.create({
          useStock,
          ...this.productMaterial
        })

        this.$message.success('Successfully added new material')
        if (this.refresh) this.refresh()
        this.show = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async searchMaterials (query) {
      try {
        if (query !== '') {
          this.materialFilter.q = {
            like: query
          }
        } else delete this.materialFilter.q

        await this.getMaterials()
      } catch (error) {
        this.$Error(error)
      }
    },
    async getMaterials () {
      try {
        const materialService = new MaterialService()
        const result = await materialService.list(null, null, { productId: { '=': this.$route.params.id }, ...this.materialFilter })
        this.pVariants = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  }
}
</script>
