<template>
  <gf-content subtitle="List of Materials for producing this product" title="Materials">
    <material-modal :is-draft="product.is_draft" :refresh="getMaterials" :visible.sync="newMaterialVisible" />
    <template #toolbar>
      <el-input v-model="search" class="mr-4" style="width: 250px"></el-input>
      <add-button text="Add Material" @click="showMaterialModal" />
    </template>
    <gf-table :data="materials" :loading="loading">
      <el-table-column label="SKU" min-width="180px" prop="variant.sku" />
      <el-table-column label="Name" min-width="170px" prop="variant.name" />
      <el-table-column label="Quantity" min-width="170px" prop="quantity">
        <template v-slot="slot">
          <span v-if="slot.row.edit">
            <el-input v-model="slot.row.quantity" size="mini" style="width: 100px"></el-input>
          </span>
          <span v-else>
            {{ slot.row.quantity }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="action">
        <template v-slot="slot">
          <el-button-group v-if="slot.row.edit">
            <el-button icon="el-icon-check" size="mini" type="success" @click="confirmSaveMaterial(slot.$index, slot.row)" />
            <el-button icon="el-icon-close" size="mini" type="info" @click="cancelEditMaterial(slot.$index)" />
          </el-button-group>
          <el-button-group v-else>
            <el-button icon="el-icon-edit" size="mini" type="primary" @click="editMaterial(slot.$index)" />
            <el-button icon="el-icon-delete" size="mini" type="danger" @click="confirmDeleteMaterial(slot.row.id)" />
          </el-button-group>
        </template>
      </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination"></gf-pagination>
    </template>
  </gf-content>
</template>
<script>
import GfContent from '@/custom-elements/gf-content'
import GfTable from '@/custom-elements/gf-table'
import GfPagination from '@/custom-elements/gf-pagination'
import AddButton from '@/component-v2/AddButton'

import ProductService from '@/services/v1/Product'
import ProductMaterialService from '@/services/v1/ProductMaterial'

import { cloneDeep, debounce } from 'lodash'
import MaterialModal from '@/views/pages/inventory/products/views/components/MaterialModal'

export default {
  components: { MaterialModal, AddButton, GfTable, GfContent, GfPagination },
  data () {
    return {
      search: '',
      loading: false,
      newMaterialVisible: false,
      product: {
        name: null,
        is_draft: false
      },
      materials: [],
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      },
      filter: {}
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getMaterials()
    }, 400)
  },
  computed: {
    isEdit: function () {
      return this.materials.filter(f => f.edit).length > 0
    }
  },
  methods: {
    confirmSaveMaterial (index, data) {
      if (!this.product.is_draft) {
        this.$confirm('You are updating the quantity of the material of the product. Do you also want to adjust the stock associated with it?', 'Notification', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'info',
          distinguishCancelAndClose: true,
          showClose: true
        }).then(() => {
          this.saveMaterial(index, data, true)
        }).catch((action) => {
          if (action === 'cancel') this.saveMaterial(index, data, false)
        })
      } else {
        this.saveMaterial(index, data, false)
      }
    },
    async saveMaterial (index, data, useStock) {
      try {
        this.loading = true

        const productMaterialService = new ProductMaterialService(this.$route.params.id, data.id)
        const newData = cloneDeep(data)
        delete newData.edit
        await productMaterialService.put({
          useStock,
          ...newData
        })

        this.$message.success('Material information successfully updated!')
        this.materials[index].edit = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    editMaterial (index) {
      this.materials[index].edit = true
    },
    confirmDeleteMaterial (id) {
      this.$confirm('This will remove the material under this product. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (!this.product.is_draft) {
          this.$confirm('You are updating the quantity of the material of the product. Do you also want to adjust the stock associated with it?', 'Notification', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'info',
            distinguishCancelAndClose: true
          }).then(() => {
            this.deleteMaterial(id, true)
          }).catch((action) => {
            if (action === 'cancel') this.deleteMaterial(id, false)
          })
        } else {
          this.deleteMaterial(id, false)
        }
      }).catch(() => {
      })
    },
    async deleteMaterial (id, useStock) {
      try {
        this.loading = true
        const productMaterialService = new ProductMaterialService(this.$route.params.id, id)
        await productMaterialService.del(`?useStock=${useStock}`)

        this.$message.success('Material successfully removed under the product')
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
        await this.getMaterials()
      }
    },
    cancelEditMaterial (index) {
      this.materials[index].edit = false
    },
    showMaterialModal () {
      this.newMaterialVisible = true
    },
    async getProduct () {
      try {
        const pdService = new ProductService(this.$route.params.id)
        const response = await pdService.get()
        this.product.name = response.data.name
        this.product.is_draft = response.data.is_draft
      } catch (error) {
        this.$Error(error)
      }
    },
    async getMaterials () {
      try {
        this.loading = true

        const productMaterialService = new ProductMaterialService(this.$route.params.id)
        const response = await productMaterialService.list(this.pagination.page, null, this.filter)
        this.materials = response.data.rows.map(d => ({ ...d, edit: false }))

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 2)
    await this.getProduct()
    await this.getMaterials()

    await this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' },
      { title: this.product.name },
      { title: 'Materials ' }
    ])
  }
}
</script>
